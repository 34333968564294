import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
// import Process from "./Process";

const AI = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "100vh",
            backgroundImage: "url('/assets/SS31.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            position: "relative", // Make the parent container relative
          }}
        >
          <div className="container d-flex align-items-center position-relative h-50">
            <div className="row w-100">
              <div className="col-md-6 align-items-center">
                <div
                  className="animate__animated animate__bounce animate__repeat-2"
                  style={{ color: "white" }}
                >
                  <h1
                    className=""
                    style={{
                      color: "white",
                      marginTop: "40%",
                    }}
                  >
                    Artifical Intellegence
                  </h1>
                  <br />
                </div>
                <p
                  className="animate__animated animate__fadeInLeft"
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  Unleash the Power of Artificial Intelligence: Elevating Your
                  Services with Cutting-Edge Technology
                </p>
              </div>
            </div>
          </div>

          <Link
            activeclassName="active"
            to="container"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="down-button"
            style={{
              position: "absolute",
              bottom: "70px",
              left: "50%",
              transform: "translateX(-50%)", // Center the button horizontally
              cursor: "pointer",
            }}
          >
            <div>
              <i
                className="fas fa-angle-down animate__animated animate__bounce animate__repeat-3"
                style={{
                  fontSize: "30px",
                  textDecoration: "none",
                  color: "white",
                }}
              ></i>
            </div>
          </Link>
        </div>
        <div
          className="text-center"
          style={{
            marginTop: "8%",
          }}
        >
          <div className="container text-center">
            <div className="row align-items-center">
              <div
                className="col-md-5"
                style={{
                  textAlign: "start",
                  color: "black",
                }}
              >
                <h2 style={{ color: "#02a9eb" }}>
                  Transform your product landscape with AI-driven solutions
                  designed for resilience
                </h2>
              </div>
              <div
                className="col-md-7"
                style={{
                  textAlign: "justify",
                  fontSize: "17px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                <p>
                  Our team of AI engineers works smartly to optimize
                  performance, and Trusted by global enterprises, we specialize
                  in testing, monitoring, and maintaining mission-critical
                  digital assets and systems
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid items-center justify-content-center mt-5"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundColor: "#F7FFED",
          }}
        >
          <div className="container text-center p-5">
            <h3 className="mb-5" style={{ textAlign: "start", color: "grey" }}>
              AI SERVICES
            </h3>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/robot.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                      Model creation
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                {" "}
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/chatbot.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                      Chatbots
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                {" "}
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/Mind.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                      Al Atuomation
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                {" "}
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/Servilance.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                      Video servilance
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Process/> */}
      </div>
    </>
  );
};

export default AI;
