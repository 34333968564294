import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Reviews from "./Reviews";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "95vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundImage: "url('/assets/AI.jpg')", // Default background image
          }}
        >
          <div className="row g-0">
            <div className="col-md-4">
              <div
                className="animate__animated animate__bounce animate__repeat-2"
                style={{ marginTop: "110px", color: "white" }}
              >
                <h1
                  className=""
                  style={{
                    color: "white",
                    fontSize: "65px",
                    fontWeight: "800",
                  }}
                >
                  Exploration, Creativity, Ingenuity.
                </h1>
                <br />
              </div>
              <h5
                className="animate__animated animate__fadeInLeft"
                style={{ color: "white", fontWeight: "700" }}
              >
                Where Ideas Come to Life In The World Of Apps
              </h5>
            </div>
          </div>

          {/* Downward arrow with scroll to OurProducts */}
          <Link
            activeclassName="active"
            to="ourproducts"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="position-absolute animate__animated animate__bounce animate__repeat-3 "
            style={{ bottom: "40px", cursor: "pointer" }}
          >
            <i
              className="fas fa-angle-down"
              style={{
                fontSize: "30px",
                textDecoration: "none",
                color: "white",
                position: "relative",
              }}
            ></i>
          </Link>
        </div>
        <div
          id="ourproducts"
          className="container-fluid items-center justify-content-center"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundImage: "url('/assets/SS3.jpg')", // Background image for 'ourproducts' section
          }}
        >
          <div className="row g-0">
            <div className="col-md-12" data-aos="fade-up">
              <h1
                className="text-center animate__animated animate__fadeInUp"
                style={{
                  marginTop: "110px",
                  color: "white",
                  fontSize: "60px",
                  fontWeight: "700",
                }}
              >
                Checkout Our Products
              </h1>
              <br />
              <p
                className="mt-3 text-center animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                The echoes of apps resonate deeply in the hearts of millions,
                <br />
                Code Knitters envisions furnishing users with <br />
                tools for faster, enhanced, and efficient <br />
                Photo and Video editing!
              </p>
            </div>
          </div>
          <br />

          <div className="container text-center" data-aos="fade-up">
            <div className="row align-items-center">
              <div className="col-md-4">
                <a
                  href="https://apps.apple.com/us/app/imagine-ai-ai-art-generator/id6450603126?platform=iphone"
                  className=""
                >
                  <img
                    src="assets/Imagine.jpg"
                    style={{
                      width: "70%",
                      height: "70%",
                      borderRadius: "10%",
                    }}
                    className=""
                  />
                  <div className="col">
                    <img
                      src="/assets/ImagineAi.png"
                      style={{
                        width: "30%",
                        height: "30%",
                        marginTop: "20px",
                      }}
                      className=""
                    />
                  </div>
                </a>
                <h4
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginTop: "30px",
                  }}
                >
                  Imagine AI
                </h4>
                <p
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    marginTop: "30px",
                  }}
                >
                  Unleash your artistic potential and give life to your wildest
                  imagination with Imagine AI
                </p>
              </div>

              <div className="col-md-4">
                <a
                  href="https://apps.apple.com/us/app/photo-lab-ai-photo-editor/id6443653724?platform=iphone"
                  className=""
                >
                  <img
                    src="/assets/Artboard2.jpg"
                    style={{
                      width: "70%",
                      height: "70%",
                      borderRadius: "10%",
                    }}
                    className=""
                  />
                  <div className="col">
                    <img
                      src="/assets/dripclipicon.png"
                      style={{
                        width: "30%",
                        height: "30%",
                        marginTop: "20px",
                      }}
                      className=""
                    />
                  </div>
                </a>
                <h4
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    marginTop: "30px",
                  }}
                >
                  Photo Lab
                </h4>
                <p
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    marginTop: "30px",
                  }}
                >
                  AI Photo Editor with AI Photo Templates, AI Photo Enhancer,
                  Remove Backgrounds, Dripping Effects and AI photo Effects e.g.
                  Anime Art, Toonme, AI Art, AI Profile Pic etc.
                </p>
              </div>
              <div className="col-md-4">
                <a
                  href="https://apps.apple.com/us/app/video-background-changer/id1596738276"
                  className=""
                >
                  <img
                    src="/assets/LBG.jpg"
                    style={{
                      width: "70%",
                      height: "70%",
                      marginTop: "20px",
                      borderRadius: "10%",
                    }}
                    className=""
                  />
                  <div className="col">
                    <img
                      src="/assets/VBGC.png"
                      style={{
                        width: "30%",
                        height: "30%",
                        marginTop: "20px",
                      }}
                      className="rounded"
                    />
                  </div>
                </a>
                <h4
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    marginTop: "30px",
                  }}
                >
                  Video Background Changer
                </h4>
                <p
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    marginTop: "30px",
                  }}
                >
                  Live Video Background Changer is a revolutionary video editor
                  App to change video background in seconds.
                </p>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
        <Reviews />
      </div>
    </>
  );
};

export default Header;
