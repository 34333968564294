import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

const Products = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "110vh",
            backgroundImage: "url('/assets/SS1.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        >
          <div className="row g-0">
            <div className="col-md-18">
              <h1
                className="col text-center animate__animated animate__fadeInUp"
                style={{
                  marginTop: "120px",
                  color: "white",
                  fontSize: "70px",
                  fontWeight: "700",
                }}
              >
                Imagine. Create. Amaze.
              </h1>
              <br />
              <p
                className="mt-3  text-center animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                We design user-friendly products with supercharged technology
                for effortless photo editing & video editing,
                <br /> ensuring amazing results that will dazzle you!
              </p>
            </div>
          </div>
          <Link
            activeclassName="active"
            to="ourproducts"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="position-absolute animate__animated animate__bounce animate__repeat-3"
            style={{ bottom: "20px", cursor: "pointer" }}
          >
            <i
              className="fas fa-angle-down"
              style={{
                fontSize: "30px",
                textDecoration: "none",
                color: "white",
              }}
            ></i>
          </Link>
        </div>

        <div
          id="ourproducts"
          className="container-fluid items-center justify-content-center"
          style={{
            //   height: "300vh",
            backgroundImage: "url('/assets/SS6.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <div className="row g-0">
            <div className="col-md-18" data-aos="fade-up">
              <h1
                className="text-center"
                style={{
                  marginTop: "130px",
                }}
              >
                <div className="container text-center">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <img
                        className="mt-5 img-fluid"
                        style={{ borderRadius: "15%" }}
                        src="/assets/3.jpg"
                        
                      />
                    </div>
                    <div
                      className="col-md-6 mt-5"
                      style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      <img
                        className=""
                        src="/assets/dripclipicon.png"
                        
                        width="80vw"
                        height="80vh"
                      />
                      <br />
                      <h1>Photo Lab</h1>
                      <h5>AI Photo Editor</h5>
                      <br />
                      AI Photo Editor with AI Photo Templates, AI Photo
                      Enhancer, Remove Backgrounds, Dripping Effects and AI
                      photo Effects e.g. Anime Art, Toonme, AI Art, AI Profile
                      Pic etc. Photo lab: AI photo editor is an intuitive and
                      powerful editor tool app that enables you to transform
                      your pictures creatively into a work of art. With a wide
                      range of features that allow you to use a number of
                      creative backgrounds with easily adjust the color,
                      contrast, brightness & saturation of photos. The pictures
                      edited with this photo editor give your photos unique look
                      and feel.
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="container text-center">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      style={{ marginTop: "50px", textDecoration: "none" }}
                    >
                      <div
                        className="text-center mt-1"
                        style={{ fontSize: "0.75em" }}
                      >
                        <span className="text-warning">
                          &#9733;&#9733;&#9733;&#9733;&#9734;
                        </span>
                        <br />
                        <span className="text-center text-warning">4.6</span>
                        <span className="" style={{ color: "white" }}>
                          <span></span> / 5
                        </span>
                      </div>
                      <a href="https://apps.apple.com/us/app/photo-lab-ai-photo-editor/id6443653724">
                        <img
                          className=""
                          src="/assets/appstore.png"
                          
                          width="160vw"
                          height="80vh"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </h1>
              <br />
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-18" data-aos="fade-up">
              <h1
                className="text-center"
                style={{
                  marginTop: "130px",
                }}
              >
                <div className="container text-center">
                  <div className="row align-items-center">
                    <div className="col">
                      <div
                        className="col"
                        style={{
                          textAlign: "justify",
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "white",
                          //   background: "white",
                          //   borderColor: "black",
                          //   borderRadius: "10%",
                        }}
                      >
                        <img
                          className=""
                          src="/assets/VBGC.png"
                          
                          width="90vw"
                          height="80vh"
                        />
                        <br />
                        <h2>Video Background Changer</h2>
                        <br />
                        Live Video Background Changer is a revolutionary video
                        editor App to change video background in seconds. Add
                        any background image or video to your video as its
                        background
                        <br />
                        <br />
                        Video Background Remover & Changer brings an
                        extraordinary environment to change or remove video
                        background instantly. You can add any image background
                        or Picture from the camera or gallery. Video Auto
                        Background remover will also help you to add or change
                        background music and can also give you an option to add
                        gif's an animated video background. All video background
                        changer comes with a huge amount of backgrounds and
                        animations to replace them in your video backgrounds. We
                        will add more background and Gifs in the future to
                        provide you latest gifs to add to your videos. You can
                        also change a background in Live video recording with
                        the Live Background Changer feature.
                        <br />
                        <br />
                      </div>
                    </div>
                    <div className="col-md-6 mt-5">
                      <img
                        className="mt-5 img-fluid"
                        style={{ borderRadius: "15%" }}
                        src="/assets/5.jpg"
                        
                      />
                    </div>
                  </div>
                </div>
                <div className="container text-center">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      style={{ marginTop: "50px", textDecoration: "none" }}
                    >
                      <div
                        className="text-center mt-1"
                        style={{ fontSize: "0.75em" }}
                      >
                        <span className="text-warning">
                          &#9733;&#9733;&#9733;&#9733;&#9734;
                        </span>
                        <br />
                        <span className="text-center text-warning">4.4</span>
                        <span className="" style={{ color: "white" }}>
                          <span></span> / 5
                        </span>
                      </div>
                      <a href="https://apps.apple.com/us/app/video-background-changer/id1596738276">
                        <img
                          className=""
                          src="/assets/appstore.png"
                          
                          width="160vw"
                          height="80vh"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </h1>
              <br />
              {/* Add video here */}
              <div className="container text-center">
                <div className="row align-items-center">
                  <div className="col" style={{ marginTop: "150px" }}>
                    <video width="100%" height="500" controls>
                      <source src="/assets/VBGCVideo.mov" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-md-18" data-aos="fade-up">
                <h1
                  className="text-center"
                  style={{
                    marginTop: "130px",
                  }}
                >
                  <div className="container text-center">
                    <div className="row align-items-center">
                      <div className="col">
                        <div
                          className="col"
                          style={{
                            textAlign: "justify",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "white",
                            //   background: "white",
                            //   borderColor: "black",
                            //   borderRadius: "10%",
                          }}
                        >
                          <img
                            className=""
                            src="/assets/ImagineAi.png"
                            
                            width="90vw"
                            height="80vh"
                          />
                          <br />
                          <h2>Imagine AI : AI Art Generator</h2>
                          <br />
                          Turn your text into stunning, lifelike art images!
                          Explore our groundbreaking Anime and realistic AI
                          models with 100+ styles and create hyper realistic
                          masterpieces! <br />
                          <br />
                          Unleash your artistic potential and give life to your
                          wildest imagination with Imagine AI : AI Art
                          Generator. Dive into a world where words evolve into
                          captivating artwork! <br />
                          <br />
                          Simply enter a prompt, select an artistic style, and
                          let Imagine AI transform your ideas into breathtaking
                          wallpapers, images, paintings, and drawings in an
                          instant!
                          <br />
                          <br />
                        </div>
                      </div>
                      <div className="col-md-6 mt-5">
                        <img
                          className="mt-5 img-fluid"
                          style={{ borderRadius: "15%" }}
                          src="/assets/4.jpg"
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container text-center">
                    <div className="row align-items-center">
                      <div
                        className="col"
                        style={{ marginTop: "50px", textDecoration: "none" }}
                      >
                        <div
                          className="text-center mt-1"
                          style={{ fontSize: "0.75em" }}
                        >
                          <span className="text-warning">
                            &#9733;&#9733;&#9733;&#9733;&#9734;
                          </span>
                          <br />
                          <span className="text-center text-warning">4.2</span>
                          <span className="" style={{ color: "white" }}>
                            <span></span> / 5
                          </span>
                        </div>
                        <a href="https://apps.apple.com/us/developer/code-knitters/id1720532207">
                          <img
                            className=""
                            src="/assets/appstore.png"
                            
                            width="160vw"
                            height="80vh"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </h1>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
