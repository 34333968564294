import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import Stragey from "./Stragey";
import Process from "./Process";
const Software = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "100vh",
            backgroundImage: "url('/assets/SS23.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            position: "relative", // Make the parent container relative
          }}
        >
          <div className="container d-flex align-items-center position-relative h-50">
            <div className="row w-100">
              <div className="col-md-6 align-items-center">
                <div
                  className="animate__animated animate__bounce animate__repeat-2"
                  style={{ color: "white" }}
                >
                  <h1
                    className=""
                    style={{
                      color: "white",
                      marginTop: "40%",
                    }}
                  >
                    Crafting intelligent experiences via the principles of
                    design thinking.
                  </h1>
                  <br />
                </div>
                <p
                  className="animate__animated animate__fadeInLeft"
                  style={{ color: "white" }}
                >
                  Initiate product design swiftly, validate product direction,
                  and produce operational prototypes within a matter of weeks.
                </p>
              </div>
            </div>
          </div>

          <Link
            activeclassName="active"
            to="software"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="down-button"
            style={{
              position: "absolute",
              bottom: "70px",
              left: "50%",
              transform: "translateX(-50%)", // Center the button horizontally
              cursor: "pointer",
            }}
          >
            <div>
              <i
                className="fas fa-angle-down animate__animated animate__bounce animate__repeat-3"
                style={{
                  fontSize: "30px",
                  textDecoration: "none",
                  color: "white",
                }}
              ></i>
            </div>
          </Link>
        </div>
        <div
          className="text-center"
          style={{
            marginTop: "8%",
          }}
        >
          <div id="software" className="container text-center">
            <div className="row align-items-center">
              <div
                className="col-md-5"
                style={{
                  textAlign: "start",
                  color: "black",
                }}
              >
                <h2 style={{ color: "#02a9eb" }}>
                  Engage in intelligent and swift product development
                  experiences
                </h2>
              </div>
              <div
                className="col-md-7"
                style={{
                  textAlign: "justify",
                  fontSize: "17px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                <p>
                  Creating a successful Minimum Viable Product (MVP) demands a
                  blend of design thinking, precise direction, lean UX
                  principles, and an agile approach. Our exclusive Lean Product
                  Accelerator™ methodology empowers us to craft robust
                  prototypes in just 4-8 weeks, marking the initial stride
                  towards crafting captivating digital journeys.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-center"
          style={{
            marginTop: "8%",
          }}
        >
          <div className="container text-center">
            <div className="row align-items-center">
              <div
                className="col-md-6"
                style={{
                  textAlign: "justify",
                  fontSize: "17px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                <img
                  className="mt-5 mb-5 img-fluid"
                  style={{ borderRadius: "10px", height: "350px" }}
                  src="/assets/SS24.jpg"
                />

                <p>
                  Built upon the foundation of design thinking and infused with
                  psychology-driven research methodologies, this low-risk
                  approach serves as the inaugural phase in crafting engaging
                  digital experiences for your clientele.
                </p>
              </div>
              <div
                className="col-md-6"
                style={{
                  textAlign: "justify",
                  fontSize: "17px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                <img
                  className="mt-5 mb-5 img-fluid"
                  style={{ borderRadius: "10px", height: "350px" }}
                  src="/assets/SS15.jpg"
                />
                <p>
                  Our team comprises a diverse set of design strategists,
                  technologists, product engineers, and researchers, united in
                  our dedication to translating concepts into actionable plans
                  and those plans into tangible, successful products.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Stragey/>
        <Process/>
      </div>
    </>
  );
};

export default Software;
