import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
// import Process from "./Process";

const Quality = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "100vh",
            backgroundImage: "url('/assets/SS30.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            position: "relative", // Make the parent container relative
          }}
        >
          <div className="container d-flex align-items-center position-relative h-50">
            <div className="row w-100">
              <div className="col-md-6 align-items-center">
                <div
                  className="animate__animated animate__bounce animate__repeat-2"
                  style={{ color: "white" }}
                >
                  <h1
                    className=""
                    style={{
                      color: "white",
                      marginTop: "40%",
                    }}
                  >
                    Quality Assurance
                  </h1>
                  <br />
                </div>
                <p
                  className="animate__animated animate__fadeInLeft"
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  Reduce time to market, reduce development expenses, and
                  streamline delivery processes.
                </p>
              </div>
            </div>
          </div>

          <Link
            activeclassName="active"
            to="Quality"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="down-button"
            style={{
              position: "absolute",
              bottom: "70px",
              left: "50%",
              transform: "translateX(-50%)", // Center the button horizontally
              cursor: "pointer",
            }}
          >
            <div>
              <i
                className="fas fa-angle-down animate__animated animate__bounce animate__repeat-3"
                style={{
                  fontSize: "30px",
                  textDecoration: "none",
                  color: "white",
                }}
              ></i>
            </div>
          </Link>
        </div>
        <div
          className="text-center"
          style={{
            marginTop: "8%",
          }}
        >
          <div id="Quality" className="container text-center">
            <div className="row align-items-center">
              <div
                className="col-md-5"
                style={{
                  textAlign: "start",
                  color: "black",
                }}
              >
                <h2 style={{ color: "#02a9eb" }}>
                  Craft resilient products through meticulous testing.
                </h2>
              </div>
              <div
                className="col-md-7"
                style={{
                  textAlign: "justify",
                  fontSize: "17px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                <p>
                  Our certified QA engineers and security professionals
                  collaborate with your team to enhance performance, mitigate
                  risks, and maximize development resources. Trusted by
                  businesses worldwide, we test, monitor, and maintain their
                  most critical digital products and systems.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid items-center justify-content-center mt-5"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundColor: "#F7FFED",
          }}
        >
          <div className="container text-center p-5">
            <h3 className="mb-5" style={{ textAlign: "start", color: "grey" }}>
              QA SERVICES
            </h3>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/pen.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                      Prototype testing
                    </span>
                  </p>
                </div>

                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/API.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                      API testing
                    </span>
                  </p>
                </div>

                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/Test.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                      Test Atuomation
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                {" "}
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/verified.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                    Application testing
                    </span>
                  </p>
                </div>
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/shield.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                    Requirements testing

                    </span>
                  </p>
                </div>
                <div className="row">
                  <p style={{textAlign: "start"}}>
                    <img
                      src="/assets/tasks.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                    Security & penetration testing
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                {" "}
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/merge.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                    Version control

                    </span>
                  </p>
                </div>
                <div className="row">
                  <p style={{ textAlign: "start" }}>
                    <img
                      src="/assets/loading.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                    Load testing

                    </span>
                  </p>
                </div>
                <div className="row">
                  <p style={{textAlign: "start"}}>
                    <img
                      src="/assets/ab-testing.png"
                      style={{ height: "60px", width: "50px" }}
                    />
                    <span className="mx-3" style={{ color: "black" }}>
                    Usability testing
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Process/> */}
      </div>
    </>
  );
};

export default Quality;
