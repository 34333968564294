import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
// import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import AOS from "aos";
import "aos/dist/aos.css";
import Buttongroup from "./Buttongroup";
import Solutions from "./Solutions";
import Platforms from "./Platforms";
import Emergingtech from "./Emergingtech";
import Focus from "./Focus";

const Services = () => {
  // const navigate = useNavigate(); // Initialize navigate hook
  const [isSelected, setIsSelected] = useState(0);
  const buttons = [ "Solutions", "Platforms", "Emerging Tech"];
  const RenderComponent = ({ index }) => {
    switch (index) {
      // case 0:
      //   // return <Focus />;
      //   break;
      case 0:
        return <Solutions />;
        break;
      case 1:
        return <Platforms />;
        break;
      case 2:
        return <Emergingtech />;
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "95vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundImage: "url('/assets/SS8.jpg')", // Default background image
          }}
        >
          <div className="container d-flex align-items-center position-relative h-100">
            <div className="row w-100">
              <div className="col-md-6 align-items-center">
                <div
                  className="animate__animated animate__bounce animate__repeat-2"
                  style={{ color: "white" }}
                >
                  <h1
                    className=""
                    style={{
                      color: "white",
                    }}
                  >
                    End-To-End Software Development Services
                  </h1>
                  <br />
                </div>
                <h5
                  className="animate__animated animate__fadeInLeft"
                  style={{ color: "white" }}
                >
                  We are helping customers digitalize existing business, build
                  innovative new products, and augment teams with high
                  performance members
                </h5>
              </div>
            </div>
          </div>

          <Link
            activeclassName="active"
            to="services"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="down-button"
            style={{
              position: "absolute",
              bottom: "70px",
              left: "50%",
              transform: "translateX(-50%)", // Center the button horizontally
              cursor: "pointer",
            }}
          >
            <div>
              <i
                className="fas fa-angle-down animate__animated animate__bounce animate__repeat-3"
                style={{
                  fontSize: "30px",
                  textDecoration: "none",
                  color: "white",
                }}
              ></i>
            </div>
          </Link>
        </div>

        <div
          className="container-fluid items-center justify-content-center bg-light"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <div id="services" className="row g-0">
            <div className="col-md-18" data-aos="fade-up">
              <div
                className="text-center"
                style={{
                  marginTop: "50px",
                }}
              >
                {/* <Buttongroup
                  buttons={buttons}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                /> */}
                <RenderComponent index={isSelected} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
