import React from "react";

const Process = () => {
  return (
    <>
      <div
        className="container d-flex align-items-center position-relative h-100 "
        style={{ marginTop: "65px" }}
      >
        <div className="row w-100">
          <div
            className="animate__animated animate__bounce animate__repeat-2"
            style={{ color: "white" }}
          >
            <h1
              className=""
              style={{
                color: "Black",
              }}
            >
              Our Process
            </h1>
            <br />
          </div>
        </div>
      </div>

      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS26.jpg"
                  />
                </div>
                <></>
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  <p
                    className="mb-3"
                    style={{
                      color: "#02a9eb",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    Empathize
                  </p>
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Foster a profound comprehension of the driving forces behind
                    your audience's motivations.
                  </p>

                  <ul
                    style={{
                      fontSize: "20px",
                     
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <li>Alignment among stakeholders</li>
                    <li>Competitive analysis</li>
                    <li>Mapping of customer journeys</li>
                    <li>Collaborative whiteboard sessions for co-creation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS27.jpg"
                  />
                </div>
              
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  <p className="mb-3" style={{
                      color: "#02a9eb",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}>
                    Define
                  </p>
                  <p style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}>
                    Specific challenges users face with existing solutions or
                    processes.
                  </p>
                  <ul
                    style={{
                      fontSize: "20px",
                     
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <li>Understanding User Needs</li>
                    <li>Identifying Pain Points</li>
                    <li>Defining User Goals</li>
                    <li>Crafting a Point of View</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS16.jpg"
                  />
                </div>

                <div
                  className="col-md-6 mt-5"
                >
                  <p className="mb-3" style={{
                      color: "#02a9eb",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}>
                    Ideate
                  </p>
                  <p style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}>
                    BrainStrom and come with creative Solution
                  </p>
                  <ul
                    style={{
                      fontSize: "20px",
                     
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <li>Diverse Perspectives</li>
                    <li>Problem Framing</li>
                    <li>Iterative Ideation</li>
                    <li>Prototyping and Testing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        <div className="row g-0">
          <div className="col-md-18" data-aos="fade-up">
            <div className="text-center">
              <div className="container text-center">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <img
                      className="mt-5 img-fluid"
                      style={{ borderRadius: "10px", height: "350px" }}
                      src="/assets/SS28.jpg"
                    />
                  </div>

                  <div
                    className="col-md-6 mt-5"
                  >
                    <p className="mb-3" style={{
                      color: "#02a9eb",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}>
                      Prototype
                    </p>
                    <p style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}>
                      Co-create an Experience That Will Inspire and Delight Your
                      Customers
                    </p>
                    <ul
                    style={{
                      fontSize: "20px",
                     
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                      <li>Wireframing</li>
                      <li>Prototyping</li>
                      <li>Usability testing</li>
                      <li>Product roadmap</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
