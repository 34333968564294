import React from 'react'

const Platforms = () => {
  return (
    <>
              <div className="row g-0">
            <div className="col-md-18" data-aos="fade-up">
              <div
                className="text-center"
                style={{
                  marginTop: "130px",
                }}
              >
                <div className="container text-center">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <img
                        className="mt-5 img-fluid"
                        style={{ borderRadius: "2%", height: "350px" }}
                        src="/assets/SS9.jpg"
                        alt="Code Knitters Logo"
                      />
                    </div>
                    <div
                      className="col-md-6 mt-5"
                      style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      <h1 style={{ color: "#02a9eb" }}>INNOVATION</h1>
                      <h5>
                        Creating transformational new digital products at the
                        intersection of market opportunity, customer experience,
                        and emerging technology
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-18" data-aos="fade-up">
              <div
                className="text-center"
                style={{
                  marginTop: "130px",
                }}
              >
                <div className="container text-center">
                  <div className="row align-items-center">
                    <div
                      className="col-md-6 mt-5"
                      style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      <h1 style={{ color: "#02a9eb" }}>MODERNIZATION</h1>
                      <h5>
                        Re-architecting legacy technology for better speed,
                        scale, security, and user experience
                      </h5>
                    </div>
                    <div className="col-md-5">
                      <img
                        className="mt-5 img-fluid"
                        style={{ borderRadius: "2%", height: "350px" }}
                        src="/assets/SS10.jpg"
                        alt="Code Knitters Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <div className="row g-0">
            <div className="col-md-18" data-aos="fade-up">
              <div
                className="text-center"
                style={{
                  marginTop: "130px",
                }}
              >
                <div className="container text-center">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <img
                        className="mt-5 img-fluid"
                        style={{ borderRadius: "2%", height: "350px" }}
                        src="/assets/SS11.jpg"
                        alt="Code Knitters Logo"
                      />
                    </div>
                    <></>
                    <div
                      className="col-md-6 mt-5"
                      style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      <h1 style={{ color: "#02a9eb" }}>DIGITALIZATION</h1>
                      <p style={{ fontSize: "20px" }}>
                        Building intelligent and low-friction digital solutions
                        to automate manual and inefficient business processes
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        
    </>
  )
}

export default Platforms