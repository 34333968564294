import React from "react";

const Process = () => {
  return (
    <>
      <div
        className="container d-flex align-items-center position-relative h-100 "
        style={{ marginTop: "65px" }}
      >
        <div className="row w-100">
          <div
            className="animate__animated animate__bounce animate__repeat-2"
            style={{ color: "white" }}
          >
            <h1
              className=""
              style={{
                color: "Black",
              }}
            >
              Our Process
            </h1>
            <br />
          </div>
        </div>
      </div>

      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS14.jpg"
                    
                  />
                </div>
                <></>
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  <h1 style={{ color: "#02a9eb" }}>Strategy & Ideation</h1>
                  <p style={{ fontSize: "20px" }}>
                    At our core, we specialize in strategy and ideation,
                    translating your goals, project scope, and aspirations into
                    tangible realities. As a data-centric app development firm,
                    we delve deep into comprehending your target demographics
                    and conduct thorough competitor analysis. This meticulous
                    approach enables us to meticulously craft user-centric
                    applications that not only add value but also distinguish
                    themselves in the market landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS15.jpg"
                    
                  />
                </div>
                <></>
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  <h1 style={{ color: "#02a9eb" }}>User Experience & Design</h1>
                  <p style={{ fontSize: "20px" }}>
                    At every juncture of design evolution, our cadre of
                    designers and app developers operates with meticulous
                    attention to detail. From the inception of wireframes to the
                    realization of fully functional, interactive, and intuitive
                    applications, our team is dedicated to crafting an immersive
                    user experience. Collaborating with 10Pearls, an innovative
                    leader in mobile app development, empowers you to propel
                    your user experience and design to unprecedented heights.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS16.jpg"
                    
                  />
                </div>

                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  <h1 style={{ color: "#02a9eb" }}>Development and Testing</h1>
                  <p style={{ fontSize: "20px" }}>
                    The developers at our app development company follow best
                    practices for flawless coding, designing and troubleshooting
                    to create apps that truly embody our clients’ visions and
                    objectives. Vigorous testing by our QA experts at all stages
                    of app development ensures excellence in functionality,
                    usability, design and security of our final product.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        <div className="row g-0">
          <div className="col-md-18" data-aos="fade-up">
            <div className="text-center">
              <div className="container text-center">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <img
                      className="mt-5 img-fluid"
                      style={{ borderRadius: "10px", height: "350px" }}
                      src="/assets/SS17.jpg"
                      
                    />
                  </div>

                  <div
                    className="col-md-6 mt-5"
                    style={{
                      textAlign: "justify",
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    <h1 style={{ color: "#02a9eb" }}>Launch</h1>
                    <p style={{ fontSize: "20px" }}>
                      We carry out the final mobile app deployment and ensure
                      the successful release of your mobile app on the app
                      store. By partnering with our market-leading mobile app
                      development company, you get to work with development
                      teams that provide complete and ongoing support throughout
                      the launch process and well beyond it. We continue to
                      measure and monitor app performance, release the latest
                      updates and features and incorporate user feedback for
                      continuous improvement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
