import React, { useEffect } from "react";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Reviews = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          once: true,
        });
      }, []);
  return (
    <>
      <br />
      <div
        className="text-center mt-5" data-aos="fade-up"
        style={{
          fontWeight: "bold",
          fontSize: "60px",
          animation: "fadeInUp 1s ease forwards",
        }}
      >
        {/* <AnimatedNumber value={123} /> */}
        170,000+
      </div>
      <div className="text-center" style={{ fontSize: "35px" }} data-aos="fade-up">
        Total User Reviews
      </div>
      <div className="text-center mt-5" style={{ fontSize: "15px" }} data-aos="fade-up">
        Our focus has always been on creating the most intuitive products to
        empower <br /> people to express their creativity in their own style. We
        value our user’s reviews <br />
        and take them as a source of motivation to improve and do even better.
      </div>
      <br />
      <div
        className="card mx-auto mb-3 shadow-lg"
        style={{ width: "50%", borderRadius: "30px" }}
        data-aos="fade-up"
      >
        <div className="row g-0">
          <div className="col-md-5">
            <img
              src="/assets/ImagineAi.png"
              style={{
                width: "25%",
                height: "25%",
                marginTop: "50px",
                marginLeft:"38%"
              }}
              className="rounded"
              alt="..."
            />

            <h4
              className="text-center"
              style={{
                color: "Black",
                textDecoration: "none",
                fontWeight: "bolder",
                marginTop: "10px",
                fontStyle: "bold",
              }}
            >
              Imagine AI
            </h4>
            <div className="text-center mt-1" style={{ fontSize: "1.75em" }}>
              <span className="text-warning">
                &#9733;&#9733;&#9733;&#9733;&#9734;
              </span>
              <br />
              <span className="text-center text-warning">4.2</span>
              <span className="text-gray-500">
                <span></span> / 5
              </span>
            </div>
            <br />
          </div>
          <div className="col-md-6">
            <div className="card-body">
              <h5 className="card-title">AvaMoon_0_0</h5>
              <p className="card-text">
                This app is a creative gem! It excels at generating unique art
                with an impressive range of styles. The resulting artwork often
                takes my breath away. My suggestion is to add a feature that can
                create art resembling my own face from an image. That would be a
                fantastic addition! Despite this, it's a genuinely enjoyable and
                artistic app.
              </p>
              <p className="card-text">
                <small className="text-body-secondary">11/08/2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card mx-auto mb-3 shadow-lg"
        data-aos="fade-up"
        style={{ width: "50%", borderRadius: "30px" }}
      >
        <div className="row g-1">
          <div className="col-md-5">
            <img
              src="/assets/dripclipicon.png"
              style={{
                width: "25%",
                height: "25%",
                marginTop: "40px",
                marginLeft: "38%",
              }}
              className="rounded"
              alt="..."
            />

            <h4
              className="text-center"
              style={{
                color: "Black",
                textDecoration: "none",
                fontWeight: "bolder",
                marginTop: "10px",
                fontStyle: "bold",
              }}
            >
              Photo Lab
            </h4>
            <div className="text-center mt-1" style={{ fontSize: "1.75em" }}>
              <span className="text-warning">
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </span>
              <br />
              <span className="text-center text-warning">4.6</span>
              <span className="text-gray-500">
                <span></span> / 5
              </span>
            </div>
            <br />
          </div>
          <div className="col-md-6">
            <div className="card-body">
              <h5 className="card-title" style={{ marginTop: "50px" }}>
                Muhammad Noman
              </h5>
              <p className="card-text">
                I recently used this application and was impressed by its
                functionality.
              </p>
              <p className="card-text">
                <small className="text-body-secondary">12/26/2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card mx-auto mb-3 shadow-lg"
        data-aos="fade-up"
        style={{ width: "50%", borderRadius: "30px" }}
      >
        <div className="row g-0">
          <div className="col-md-5">
            <img
              src="/assets/VBGC.png"
              style={{
                width: "25%",
                height: "25%",
                marginTop: "40px",
                marginLeft: "38%",
              }}
              className="rounded"
              alt="..."
            />

            <h4
              className="text-center"
              style={{
                color: "Black",
                textDecoration: "none",
                fontWeight: "bolder",
                marginTop: "10px",
                fontStyle: "bold",
              }}
            >
              Video Background Changer
            </h4>
            <div className="text-center mt-1" style={{ fontSize: "1.75em" }}>
              <span className="text-warning">
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </span>
              <br />
              <span className="text-center text-warning">4.4</span>
              <span className="text-gray-500">
                <span></span> / 5
              </span>
            </div>
            <br />
          </div>
          <div className="col-md-6">
            <div className="card-body">
              <h5 className="card-title">Shadejah</h5>
              <p className="card-text">
                Great app Very nice app, I’ve been using it to convert my videos
                and making them look nicer and it is very easy to do and having
                the size thing when exporting the video is really useful cuz you
                can be sure that it will look good on different platforms like
                tiktok or insta
              </p>
              <p className="card-text">
                <small className="text-body-secondary">12/30/2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        className="container-fluid"
        style={{
          marginTop: "60px",
          height: "80vh",
          backgroundImage: "url('/assets/UI1.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      ></div>
    </>
  );
};

export default Reviews;
