import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import AndriodApp from "./AndriodApp";
import FlutterApp from "./FlutterApp";
import IOSApp from "./IOSApp";
import Buttongroup from "./ButtonGroup";
import Process from "./Process";
const Mobile = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [isSelected, setIsSelected] = useState(0);
  const buttons = [
    "Android App Development",
    "Flutter App Development",
    "IOS App Development",
  ];
  const RenderComponent = ({ index }) => {
    switch (index) {
      case 0:
        return <AndriodApp />;
        break;
      case 1:
        return <FlutterApp />;
        break;
      case 2:
        return <IOSApp />;
        break;

      default:
        break;
    }
  };
  return (
    <div className="overflow-hidden">
      <div
        className="d-flex container-fluid items-center justify-content-center"
        style={{
          marginTop: "55px",
          height: "100vh",
          backgroundImage: "url('/assets/SS12.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          position: "relative", // Make the parent container relative
        }}
      >
        <div className="container d-flex align-items-center position-relative h-50">
          <div className="row w-100">
            <div className="col-md-6 align-items-center">
              <div
                className="animate__animated animate__bounce animate__repeat-2"
                style={{ color: "white" }}
              >
                <h1
                  className=""
                  style={{
                    color: "white",
                    marginTop: "40%",
                  }}
                >
                  Mobile App Development
                </h1>
                <br />
              </div>
              <p
                className="animate__animated animate__fadeInLeft"
                style={{ color: "white" }}
              >
                Revolutionizing mobile applications through seamless AI
                integration is our specialty. <br /> <br /> Place your trust in
                us as your premier app development firm for cutting-edge
                solutions that drive innovation in the mobile sphere.
              </p>
            </div>
          </div>
        </div>

        <Link
          activeclassName="active"
          to="Mobile"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="down-button"
          style={{
            position: "absolute",
            bottom: "70px",
            left: "50%",
            transform: "translateX(-50%)", // Center the button horizontally
            cursor: "pointer",
          }}
        >
          <div>
            <i
              className="fas fa-angle-down animate__animated animate__bounce animate__repeat-3"
              style={{
                fontSize: "30px",
                textDecoration: "none",
                color: "white",
              }}
            ></i>
          </div>
        </Link>
      </div>
      <div
        className="container-fluid items-center justify-content-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          backgroundColor: "#F7FFED",
        }}
      >
        <div id="Mobile" className="row g-0">
          <div className="col-md-18" data-aos="fade-up">
            <div className="text-center" style={{}}>
              <img
                className="mt-3 mb-3 mr-5 img-fluid"
                style={{ borderRadius: "10px", height: "80px" }}
                src="/assets/swiftlogo.png"
                alt="Swift Logo"
              />
              <img
                className="mt-3 mb-3 mr-5 img-fluid"
                style={{ borderRadius: "10px", height: "80px" }}
                src="/assets/flutter logo.png"
                alt="Flutter Logo"
              />
              <img
                className="mt-3 mb-3 img-fluid"
                style={{ borderRadius: "10px", height: "80px" }}
                src="/assets/andriodlogo.png"
                alt="Android Logo"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="text-center"
        style={{
          marginTop: "8%",
        }}
      >
        <div className="container text-center">
          <div className="row align-items-center">
            <div
              className="col-md-5"
              style={{
                textAlign: "start",
                color: "black",
              }}
            >
              <h2 style={{ color: "#02a9eb" }}>
                Elevate Your Digital Experience with Cutting-Edge Mobile
                Solutions.
              </h2>
            </div>
            <div
              className="col-md-7"
              style={{
                textAlign: "justify",
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
              }}
            >
              <p>
                Mobile technologies are revolutionizing the business landscape
                and reshaping our interactions with the world. A robust mobile
                presence is essential for businesses, significantly enhancing
                visibility, expanding reach, and fostering stronger customer
                engagement and loyalty.
                <br />
                <br /> At Code Knitters, we empower businesses to thrive by
                delivering cutting-edge, secure, and transformative mobile
                solutions. Our state-of-the-art applications are designed to
                provide seamless user experiences that make a difference. This
                commitment to excellence has earned us global recognition as a
                premier mobile app development company.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        className="d-flex container-fluid items-center justify-content-center"
        style={{
          marginTop: "55px",
          backgroundImage: "url('/assets/SS13.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      >
        <div className="row g-0">
          <div className="col-md-18" data-aos="fade-up">
            <div className="text-center">
              <div className="container text-center">
                <div className="row align-items-center">
                  <div
                    className="col-md-6"
                    style={{
                      textAlign: "justify",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "black",
                      marginTop: "60px",
                    }}
                  >
                    <h3 style={{ color: "#02a9eb" }}>Our Services</h3>
                    <p style={{ fontSize: "20px", fontWeight: "400" }}>
                      As a forward-thinking app development firm, we specialize
                      in providing comprehensive mobility solutions tailored to
                      meet your specific needs. Our services encompass strategic
                      consultation, team augmentation, and complete app
                      development, ensuring we cover all aspects of your
                      project. Whether you're a startup or an established
                      enterprise, our track record of delivering innovative
                      mobile applications has earned us trust globally.
                    </p>
                    <Buttongroup
                      buttons={buttons}
                      isSelected={isSelected}
                      setIsSelected={setIsSelected}
                    />
                  </div>
                  <div className="col-md-5">
                    <RenderComponent index={isSelected} />
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <Process />
    </div>
  );
};

export default Mobile;
