import React, { useState, useEffect } from "react";
// import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Solutions = () => {
  const navigate = useNavigate(); // Initialize navigate hook
  const [selectedButton, setSelectedButton] = useState(null);

  let location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const handleButtonClick = (path) => {
    setSelectedButton(path);
    navigate(path); // Use navigate instead of history.push
  };

  return (
    <>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div
            className="text-center"
            style={{
              marginTop: "130px",
            }}
          >
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "2%", height: "350px" }}
                    src="/assets/SS19.jpg"
                    
                  />
                </div>
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  <h3 style={{ color: "#02a9eb" }}>MOBILITY</h3>
                  <p style={{ fontSize: "20px", fontWeight: "400" }}>
                    Enterprise mobility is key to digital transformation
                  </p>

                  <Link
                    className={`${
                      location.pathname === "/Mobile" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/Mobile"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark rounded-none"
                      style={{
                        marginTop: "60px", // Add margin from the top
                        padding: "12px 24px", // Increase the padding to increase the size
                        fontSize: "16px", // Optionally increase the font size
                      }}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div
            className="text-center"
            style={{
              marginTop: "130px",
            }}
          >
            <div className="container text-center">
              <div className="row align-items-center">
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  <h3 style={{ color: "#02a9eb" }}>SOFTWARE DEVELOPMENT</h3>
                  <p style={{ fontSize: "20px", fontWeight: "400" }}>
                    Full-stack web application development services.
                  </p>
                  <Link
                    className={`${
                      location.pathname === "/" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/Software"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark rounded-none"
                      style={{
                        marginTop: "60px", // Add margin from the top
                        padding: "12px 24px", // Increase the padding to increase the size
                        fontSize: "16px", // Optionally increase the font size
                      }}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "2%", height: "350px" }}
                    src="/assets/SS18.jpg"
                    
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>

      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div
            className="text-center"
            style={{
              marginTop: "130px",
            }}
          >
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "2%", height: "350px" }}
                    src="/assets/SS20.jpg"
                    
                  />
                </div>
               
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  <h3 style={{ color: "#02a9eb" }}>USER EXPERIENCE</h3>
                  <p style={{ fontSize: "20px", fontWeight: "400" }}>
                    An engaging, seamless user experience is the surest path to
                    business success
                  </p>
                  <Link
                    className={`${
                      location.pathname === "/" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/User-Experience"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark rounded-none"
                      style={{
                        marginTop: "60px", // Add margin from the top
                        padding: "12px 24px", // Increase the padding to increase the size
                        fontSize: "16px", // Optionally increase the font size
                      }}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div
            className="text-center"
            style={{
              marginTop: "130px",
            }}
          >
            <div className="container text-center">
              <div className="row align-items-center">
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  <h3 style={{ color: "#02a9eb" }}>QUALITY ASSURANCE</h3>
                  <p style={{ fontSize: "20px", fontWeight: "400" }}>
                    Reduce time to market, lower development costs, and
                    streamline delivery.
                  </p>
                  <Link
                    className={`${
                      location.pathname === "/" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/quality-assurance-qa"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark rounded-none"
                      style={{
                        marginTop: "60px", // Add margin from the top
                        padding: "12px 24px", // Increase the padding to increase the size
                        fontSize: "16px", // Optionally increase the font size
                      }}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "2%", height: "350px" }}
                    src="/assets/SS21.jpg"
                    
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>

      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div
            className="text-center"
            style={{
              marginTop: "130px",
            }}
          >
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "2%", height: "350px" }}
                    src="/assets/SS22.jpg"
                    
                  />
                </div>
                <></>
                <div
                  className="col-md-6 mt-5"
                  style={{
                    textAlign: "justify",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  <h3 style={{ color: "#02a9eb" }}>Artifcal Intelligence</h3>
                  <p style={{ fontSize: "20px", fontWeight: "400" }}>
                    An engaging, seamless user experience is the surest path to
                    business success
                  </p>
                  <Link
                    className={`${
                      location.pathname === "/" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/Artifical-Intellegence"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark rounded-none"
                      style={{
                        marginTop: "60px", // Add margin from the top
                        padding: "12px 24px", // Increase the padding to increase the size
                        fontSize: "16px", // Optionally increase the font size
                      }}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default Solutions;
