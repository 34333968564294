import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  let location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
  }, [location]);
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top bg-dark">
        <div className="container-fluid">
          <Link
            className={`nav-link text-light ${
              location.pathname === "/" ? "active" : ""
            }`}
            aria-current="page"
            to="/"
          >
            <img
              src="/assets/logof.png"
              alt="Code Knitters Logo"
              width="120px"
            />
          </Link>

          <button
            className="navbar-toggler bg-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/About" ? "" : "active"
                  }`}
                  to="/About"
                >
                  About
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={`nav-link dropdown-toggle text-light ${
                    location.pathname === "/Service" ? "" : "active"
                  }`}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  to="/Service"
                >
                  Services
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className={`dropdown-item ${
                        location.pathname === "/Software" ? "active" : ""
                      }`}
                      aria-current="page"
                      to="/Software"
                    >
                      Software Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`dropdown-item ${
                        location.pathname === "/Mobile" ? "active" : ""
                      }`}
                      aria-current="page"
                      to="/Mobile"
                    >
                      Mobile Apps
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`dropdown-item ${
                        location.pathname === "/User-Experience" ? "active" : ""
                      }`}
                      aria-current="page"
                      to="/User-Experience"
                    >
                      User Experience
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`dropdown-item ${
                        location.pathname === "/quality-assurance-qa" ? "active" : ""
                      }`}
                      aria-current="page"
                      to="/quality-assurance-qa"
                    >
                      Quality Assurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`dropdown-item ${
                        location.pathname === "/Artifical-Intellegence" ? "active" : ""
                      }`}
                      aria-current="page"
                      to="/Artifical-Intellegence"
                    >
                      Artifical Intellegence
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/Products" ? "" : "active"
                  }`}
                  to="/Products"
                >
                  Products
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/Team" ? "" : "active"
                  }`}
                  to="/Team"
                >
                  Team
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/Contact" ? "" : "active"
                  }`}
                  to="/Contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Blue line at the bottom */}
        <div
          style={{
            borderBottom: "2px solid",
            color: "#02a9eb",
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        ></div>
      </nav>
    </>
  );
};

export default Navbar;
