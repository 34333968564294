import React from "react";

const FlutterApp = () => {
  return (
    <div
      className="card p-3"
      style={{
        marginTop: "30px",
        marginLeft: "60px",
        borderRadius: "20px",
        backgroundColor: "#168FF7",
        color: "white",
        textAlign: "left", // Align content to the left
      }}
    >
      <div className="card-body" style={{ paddingLeft: "20px" }}>
        {" "}
        {/* Add padding-left for better alignment */}
        <img
          src="/assets/Vector-1.svg"
          className="mb-5"
          style={{
            height: "80px",
          }}
        />
        <h5 className="card-title mb-3">Flutter App Development</h5>
        <p className="card-text">
          Discover the power of cross-platform development with our Flutter app
          development services. Utilizing the Flutter framework's capabilities,
          our proficient developers create stunning applications that run
          seamlessly on both Android and iOS platforms. With a single codebase,
          we ensure consistent performance and aesthetics across devices,
          maximizing efficiency and reducing development time. Unleash the
          potential of Flutter and elevate your app's presence on multiple
          platforms with our comprehensive Flutter app development solutions.
        </p>
      </div>
    </div>
  );
};

export default FlutterApp;
