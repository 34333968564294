import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Footer from "./components/footer";
import Header from "./components/Header";
import Products from "./components/Products";
import Team from "./components/Team";
import Contact from "./components/Contact";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./components/Services";
import Solutions from "./components/Solutions";
import Mobile from "./components/Mobility/Mobile";
import Software from "./components/Software Development/Software";
import UserExperience from "./components/UserExperience/user";
import Quality from "./components/Quality Assurance/Quality";
import AI from "./components/Artifical Intellegence/AI";


function App() {
  // Use react-router-dom's useLocation hook to get the current route
  const location = useLocation();

  // Use useEffect to update the document title based on the current route
  useEffect(() => {
    // Extract the route name from the pathname
    const routeName = location.pathname.replace("/", "");

    // Capitalize the first letter of the route name
    const capitalizedRouteName =
      routeName.charAt(0).toUpperCase() + routeName.slice(1);

    // Set the document title using the route name
    document.title = `Code Knitters | ${capitalizedRouteName}`;
  }, [location.pathname]); // Re-run the effect when the pathname changes

  return (
    <>
    <>
    </>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/About" element={<About />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Service" element={<Services />} />
        <Route path="/Solution" element={<Solutions />} />
        <Route path="/Mobile" element={<Mobile />} />
        <Route path="/Software" element={<Software/>} />
        <Route path="/User-Experience" element={<UserExperience/>} />
        <Route path="/quality-assurance-qa" element={<Quality/>} />
        <Route path="/Artifical-Intellegence" element={<AI/>} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
