import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <img
                className=""
                src="/assets/logof.png"
                alt="Code Knitters Logo"
                width="200vw"
                height="80vh"
              />
              <p className="mt-3" style={{ color: "white" }}>
                Empowering Innovations that touch millions of hearts
              </p>
              <small className="mt-3" style={{ color: "white" }}>
                Where Ideas Come to Life In The World Of Apps
              </small>
            </div>
            <div className="col">
              <h1 className="mt-5" style={{ color: "white" }}>
                Contact us
              </h1>

              <div className="row">
                <p className="">
                  <i
                    className="fa-solid fa-location-dot"
                    style={{ color: "white" }}
                  ></i>
                  <span className="mx-3" style={{ color: "white" }}>
                    First Floor, National Science and Technology Park, NUST,
                    H-12, Islamabad, Pakistan
                  </span>
                </p>
              </div>
              <div className="row">
                <p className="">
                  <i
                    className="fa-solid fa-envelope"
                    style={{ color: "white" }}
                  ></i>
                  <span className="mx-3" style={{ color: "white" }}>
                    ahsan@codeknitters.co
                  </span>
                </p>
              </div>
              <div className="row">
                <div className="mb-5 ">
                  {/* <div className=""> */}
                  {/* <div className="col"> */}
                  <a href="https://www.facebook.com/codeknitters.co">
                    <i
                      className="fa-brands fa-facebook fa-2xl mt-5 mx-3"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                  {/* <div className="col"> */}

                  <a href="https://www.instagram.com/codeknitters/">
                    <i
                      className="fa-brands fa-instagram fa-2xl mx-3 my-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div>
                        <div className="col"> */}

                  <a href="https://www.linkedin.com/company/code-knitters/">
                    <i
                      className="fa-brands fa-linkedin fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div>
                        <div className="col"> */}

                  <a href="https://www.youtube.com/@codeknitters2928">
                    <i
                      className="fa-brands fa-youtube fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                  {/* <div className="col"> */}

                  <a href="https://apps.apple.com/us/developer/code-knitters/id1720532207">
                    <i
                      className="fa-brands fa-apple fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                  {/* <div className="col"> */}

                  <a href="https://play.google.com/store/apps/developer?id=Code+Knitters">
                    <i
                      className="fa-brands fa-google-play fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <footer className="bg-dark text-center py-3" style={{ color: "grey" }}>
        <p>&copy; 2024 Code Knitters. All rights reserved.</p>
      </footer>
    </>
  );
};

export default Footer;
