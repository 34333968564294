import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "100vh",
            backgroundImage: "url('/assets/SS.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        >
          <div className="row g-0">
            <div className="col-md-8">
              <h1
                className="animate__animated animate__fadeInUp"
                style={{
                  marginTop: "100px",
                  marginLeft: "30px",
                  color: "white",
                  fontSize: "50px",
                  fontWeight: "700",
                }}
              >
                Unleash your creativity to its fullest potential!
              </h1>
              <br />
              <p
                className="mt-3 animate__animated animate__fadeInUp"
                style={{ marginLeft: "30px", color: "white" }}
              >
                Code Knitters is a leading app development company that crafts
                <br />
                exceptional digital solutions.
                <br />
                <br />
                <br />
                Our passion for quality craftsmanship and seamless functionality
                sets us apart in the industry. Let us bring your app ideas to
                life and make a lasting impact in the digital world.
              </p>
              <br /> <br /> <br /> <br /> <br />
            </div>
          </div>
          <Link
            activeclassName="active"
            to="ourproducts"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="position-absolute animate__animated animate__bounce animate__repeat-3"
            style={{ bottom: "20px", cursor: "pointer" }}
          >
            <i
              className="fas fa-angle-down"
              style={{
                fontSize: "30px",
                textDecoration: "none",
                color: "white",
              }}
            ></i>
          </Link>
        </div>
        <div
          id="ourproducts"
          className="container-fluid items-center justify-content-center"
          style={{
            backgroundImage: "url('/assets/SS3.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <div className="row g-0">
            <div className="col-md-18" data-aos="fade-up">
              <h1
                className="text-center animate__animated animate__fadeInDown"
                style={{
                  marginTop: "130px",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                We believe that given the right tools,
                <br /> people have the power to do amazing things.
              </h1>
              <br />
            </div>
          </div>

          <div className="container text-center" data-aos="fade-up">
            <div
              className="row align-items-center"
              style={{ marginTop: "100px" }}
            >
              <div className="col">
                <h4
                  className="text-center"
                  style={{
                    color: "white",
                    fontSize: "60px",
                    textDecoration: "none",
                  }}
                >
                  25
                </h4>
                <p
                  className="text-center animate__animated animate__fadeInDown"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    fontSize: "20px",
                  }}
                >
                  A team of strong, passionate changemakers
                </p>
              </div>

              <div className="col">
                <h4
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "60px",
                  }}
                >
                  100K+
                </h4>
                <p
                  className="text-center animate__animated animate__fadeInDown"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    fontSize: "20px",
                    marginTop: "30px",
                  }}
                >
                  <span style={{ fontStyle: "bold" }}>Downloads</span> both on{" "}
                  <br />
                  iOS and Android
                </p>
              </div>
              <div className="col">
                <h4
                  className="text-center"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "60px",
                  }}
                >
                  32K+
                </h4>
                <p
                  className="text-center animate__animated animate__fadeInDown"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    fontSize: "20px",
                    marginTop: "30px",
                  }}
                >
                  Monthly active users
                  <br /> worldwide.
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
        <div
          className="container-fluid"
          style={{
            backgroundPosition: "center",
            color: "white",
            backgroundImage: "url('/assets/SS7.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container text-center">
            <div className="row align-items-center" data-aos="fade-up">
              <div className="col" style={{ marginLeft: "60px" }}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    marginTop: "60px",
                    width: "250px", // Adjust the size of the circle as needed
                    height: "250px",
                    borderRadius: "50%", // Make it a circle
                    overflow: "hidden", // Hide the overflow for a perfect circle
                  }}
                >
                  <img
                    className="img-fluid"
                    src="/assets/Mission.jpg"
                    alt="Code Knitters Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Maintain aspect ratio
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <h1
                  className="text-center animate__animated animate__fadeInUp"
                  style={{
                    marginTop: "50px",
                    color: "gray",
                    fontSize: "50px",
                    fontWeight: "bolder",
                  }}
                >
                  Our Mission
                </h1>
                <p
                  className="text-center animate__animated animate__fadeInUp"
                  style={{
                    color: "gray",
                  }}
                >
                  An opportunity unfolds to unveil hidden <br /> talents and
                  articulate emotions. The missions of Code Knitters <br />{" "}
                  revolve around this pursuit.
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
        <a href="https://apps.apple.com/us/app/photo-lab-ai-photo-editor/id6443653724?platform=iphone">
          <div
            className="container-fluid"
            style={{
              height: "100vh",
              backgroundImage: "url('/assets/UI2.jpg')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
            }}
          ></div>

          <style jsx>{`
            @media screen and (max-width: 768px) {
              .container-fluid {
                background-size: contain;
              }
            }
          `}</style>
        </a>
      </div>
    </>
  );
};

export default About;
