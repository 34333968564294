import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "100vh",
            backgroundImage: "url('/assets/Contact.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            position: "relative", // Make the parent container relative
          }}
        >
          <h1
            className="text-center animate__animated animate__fadeInLeft"
            style={{
              marginTop: "100px",
              color: "white",
              fontSize: "70px",
              fontWeight: "700",
              letterSpacing: "5px",
              display: "inline-block",
            }}
          >
            Feel Free To Contact
          </h1>

          <Link
            activeClass="active"
            to="container"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="down-button"
            style={{
              position: "absolute",
              bottom: "70px",
              left: "50%",
              transform: "translateX(-50%)", // Center the button horizontally
              cursor: "pointer",
            }}
          >
            <div>
              <i
                className="fas fa-angle-down animate__animated animate__bounce animate__repeat-3"
                style={{
                  fontSize: "30px",
                  textDecoration: "none",
                  color: "white",
                }}
              ></i>
            </div>
          </Link>
        </div>
        <div
          className="container-fluid"
          style={{
            height: "100vh",
          }}
        >
          <h5
            className="text-center"
            data-aos="fade-up"
            style={{
              marginTop: "100px",
              color: "black",
              fontSize: "40px",
              fontWeight: "600",
              letterSpacing: "1px",
            }}
          >
            Contact Code Knitters
          </h5>
          <p
            className="text-center"
            style={{ marginTop: "40px" }}
            data-aos="fade-up"
          >
            "We've embraced simpler and more efficient methods <br />
            to connect with you and address any questions you may have"
          </p>

          <div className="container text-center">
            <div className="row row-cols-2" data-aos="fade-up">
              <div className="col">
                <a
                  href="https://maps.app.goo.gl/BBpErJB1MnGctqed8"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="card mx-auto mb-3 shadow"
                    style={{ width: "100%", marginTop: "50px" }}
                  >
                    <div className="row g-0">
                      <div className="col">
                        <div className="card-body text-center">
                          <h4
                            className="card-title text-center"
                            style={{ marginTop: "30px" }}
                          >
                            Address
                          </h4>

                          <i
                            className="fa-solid fa-location-dot fa-2xl"
                            style={{ color: "#B197FC", marginTop: "30px" }}
                          ></i>

                          <p
                            className="card-text text-center"
                            style={{ color: "black" }}
                          >
                            <br />
                            First Floor, National Science and Technology Park,
                            NUST, H-12, Islamabad, Pakistan
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col" data-aos="fade-up">
                <a
                  href="mailto:ahsan@codeknitters.co"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="card mx-auto mb-3 shadow"
                    style={{
                      width: "100%",
                      marginTop: "50px",
                      height: "35vh",
                      transition: "transform 0.3s",
                    }}
                  >
                    <div className="row g-0">
                      <div className="col">
                        <div className="card-body text-center">
                          <h4
                            className="card-title text-center"
                            style={{ marginTop: "30px" }}
                          >
                            Email
                          </h4>
                          <i
                            className="fa-solid fa-envelope fa-2xl"
                            style={{ color: "#B197FC", marginTop: "30px" }}
                          ></i>
                          <p
                            className="card-text text-center"
                            style={{ color: "black" }}
                          >
                            <br />
                            ahsan@codeknitters.co
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <style jsx>{`
                .card:hover {
                  transform: scale(
                    1.05
                  ); /* Increase the scale of the card on hover */
                }
              `}</style>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
