import React from "react";

const AndriodApp = () => {
  return (
    <div
      className="card p-3"
      style={{
        marginTop: "30px",
        marginLeft: "60px",
        borderRadius: "20px",
        backgroundColor: "#168FF7",
        color: "white",
        textAlign: "left", // Align content to the left
      }}
    >
      <div className="card-body" style={{ paddingLeft: "20px" }}>
        {" "}
        {/* Add padding-left for better alignment */}
        <img
          src="/assets/Vector-1.svg"
          className="mb-5"
          style={{
            height: "80px",
          }}
        />
        <h5 className="card-title mb-3">Andriod App Development</h5>
        <p className="card-text">
          Embark on a journey of innovation with our Android app development
          services. Leveraging the latest tools and technologies, our skilled
          developers craft robust Android applications tailored to your unique
          requirements. From conceptualization to deployment, we ensure seamless
          integration of features and functionalities, delivering exceptional
          user experiences. Expand your presence on the Android platform and
          engage a wider audience with our bespoke Android app solutions
        </p>
      </div>
    </div>
  );
};

export default AndriodApp;
