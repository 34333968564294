import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

const Team = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="container-fluid"
          style={{
            marginTop: "55px",
            height: "100vh",
            backgroundImage: "url('/assets/SS2.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        >
          <div className="row g-0">
            <div className="col-md-8">
              <h1
                className="animate__animated animate__bounce animate__repeat-2"
                style={{
                  marginTop: "100px",
                  marginLeft: "60px",
                  color: "white",
                  fontSize: "60px",
                  fontWeight: "700",
                  letterSpacing: "1px",
                }}
              >
                Are you ready to <br /> meet the
                <br /> Knitters ?
              </h1>
              <br />
              <p
                className="mt-3 animate__animated animate__fadeInUp"
                style={{ marginLeft: "60px", color: "white" }}
              >
                Meet a team of passionate, energetic, dedicated tech
                enthusiasts. We take pride in our work and embrace challenges,
                striving to craft tech products adored and utilized by millions
                worldwide.
              </p>
            </div>
          </div>
          <Link
            activeclassName="active"
            to="ahsanAmeen"
            spy={true}
            smooth={true}
            offset={-50} // Adjust offset to fine-tune scrolling position
            duration={500}
            className="down-button animate__animated animate__bounce animate__repeat-3"
            style={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              cursor: "pointer",
            }}
          >
            <div>
              <i
                className="fas fa-angle-down"
                style={{
                  fontSize: "30px",
                  textDecoration: "none",
                  color: "white",
                }}
              ></i>
            </div>
          </Link>
        </div>
        <div
          className="container-fluid"
          style={{
            //   height: "360vh",
            backgroundImage: "url('/assets/SS4.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        >
          <div className="row g-0">
            <div className="col-md-18">
              <h1
                className="text-center animate__animated animate__fadeInUp"
                data-aos="fade-up"
                style={{
                  marginTop: "130px",
                  color: "white",
                  fontSize: "50px",
                  fontWeight: "600",
                  letterSpacing: "10px",
                }}
              >
                Meet The Knitters
              </h1>
            </div>
          </div>
          <div
            className="animate__animated animate__fadeInUp"
            data-aos="fade-up"
            style={{
              borderBottom: "3px solid #02a9eb",
              width: "300px",
              margin: "auto",
            }}
          ></div>
          <br />
          <br />
          <br />
          <p
            className="text-center animate__animated animate__fadeInUp"
            data-aos="fade-up"
          >
            Delighted to introduce our team, a collective of creative,
            eccentric, and imaginative tech enthusiasts poised to conquer the
            world.
          </p>
          {/* Circular image centered after the text */}
          <div id="ahsanAmeen" className="text-center">
            <img
              src="/assets/CEO.jpeg" // Replace with the actual image path
              alt="Team"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "50%", // Make it circular
                marginTop: "20px", // Adjust the margin as needed
              }}
            />
          </div>
          <div
            className="text-center"
            data-aos="fade-up"
            style={{ marginTop: "30px" }}
          >
            <a href="https://www.linkedin.com/in/ahsanameenbb136a207/">
              <i
                className="fa-brands fa-linkedin fa-2xl"
                data-aos="fade-up"
                style={{ color: "#85878a" }}
              ></i>
            </a>
          </div>
          <p
            className="text-center"
            data-aos="fade-up"
            style={{ marginTop: "20px", fontSize: "20px" }}
          >
            Ahsan Ameen
            <p
              className="text-center"
              data-aos="fade-up"
              style={{ color: "#02a9eb", fontSize: "13px" }}
            >
              Founder & CEO
            </p>
          </p>
          <div
            data-aos="fade-up"
            style={{
              borderBottom: "2px solid",
              color: "#02a9eb",
              width: "90%",
              margin: "auto",
              marginTop: "60px",
              bottom: 0,
            }}
          ></div>

          <div
            className="row row-cols-2 row-cols-md-4 g-2"
            data-aos="fade-up"
            style={{ marginTop: "30px" }}
          >
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/PM.jpg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/saeed-iqbal01/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Saeed Iqbal
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Project Manager
                </p>
              </p>
            </div>

            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/Senior IOS developer.jpg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/ahsanameenbb136a207/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Muhammad Usman
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Sr. IOS Developer
                </p>
              </p>
            </div>

            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/SeniorAndriodDeveloper.jpg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/theasif-hussain/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Asif Hussain
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Sr. Flutter Developer
                </p>
              </p>
            </div>
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/Salman.jpg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/salmanali987/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Salman Ali
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Sr. Android Developer
                </p>
              </p>
            </div>
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/AI senior Developer.jpg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/shaikhahsan2015/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Ahsan Sheikh
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Sr. AI Developer
                </p>
              </p>
            </div>

            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/AIIrfan.jpeg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/irfan-hussain-61427a178/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Irfan Hussain
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Jr. AI Developer
                </p>
              </p>
            </div>
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/AIjunior.JPG" // Replace with the actual image path
                  
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/muhammad-ahmad-1b8aa1241/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Muhammad Ahmad
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Jr. AI Developer
                </p>
              </p>
            </div>
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/GD.jpg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/kainat-haq-960b5a278/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                kainat Haq
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Sr. Visual Designer
                </p>
              </p>
            </div>
          </div>
          {/* <div
            data-aos="fade-up"
            style={{
              borderBottom: "2px solid",
              color: "#02a9eb",
              width: "90%",
              margin: "auto",
              marginTop: "60px",
              bottom: 0,
            }}
          ></div> */}

          <div
            className="row row-cols-2 row-cols-md-3 g-3"
            data-aos="fade-up"
            style={{ marginTop: "30px" }}
          >
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/Andrioddeveloper.jpeg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/muneeb-ur-rehman-b12295206/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Muneeb Ur Rehman
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Jr. Android Developer
                </p>
              </p>
            </div>
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/Web.jpg" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/mbs0092/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Muhammad Bin Shahid
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Website Developer
                </p>
              </p>
            </div>
            <div className="col">
              <div className="text-center">
                <img
                  src="/assets/SQA.JPG" // Replace with the actual image path
                  alt="Team"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%", // Make it circular
                    marginTop: "20px", // Adjust the margin as needed
                  }}
                />
              </div>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <a href="https://www.linkedin.com/in/abdullahzafaraz/">
                  <i
                    className="fa-brands fa-linkedin fa-2xl"
                    style={{ color: "#85878a" }}
                  ></i>
                </a>
              </div>
              <p
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Abdullah Zafar
                <p
                  className="text-center"
                  style={{ color: "#02a9eb", fontSize: "13px" }}
                >
                  Software Quality Assurance & UI/UX Designer
                </p>
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>

        <div
          className="container-fluid"
          data-aos="fade-up"
          style={{
            backgroundColor: "white",
          }}
        >
          <h1
            className="text-center"
            style={{
              marginTop: "100px",
              color: "Black",
              fontSize: "50px",
              fontWeight: "700",
              letterSpacing: "1px",
            }}
          >
            Our Culture
          </h1>
          <div
            style={{
              borderBottom: "2px solid",
              width: "100px",
              margin: "auto",
            }}
          ></div>
          <br />
          <p className="text-center">Happiness, Fun & Passion for what we do</p>

          <div
            className="card mx-auto mb-3 shadow-lg"
            data-aos="fade-up"
            style={{ width: "50vw", borderRadius: "30px", marginTop: "50px" }}
          >
            <div className="row g-0">
              <div className="col">
                <div className="card-body text-center">
                  <i
                    className="fa-solid fa-people-group fa-2xl"
                    style={{ color: "#B197FC", marginTop: "30px" }}
                  ></i>
                  <h4
                    className="card-title text-center"
                    style={{ marginTop: "30px" }}
                  >
                    Learning & Career Development
                  </h4>
                  <p
                    className="card-text text-center"
                    style={{ color: "black" }}
                  >
                    {/* We offer our team members the chance to expand their knowledge
                  and skills by learning from their colleagues.  */}
                    We actively promote collaboration and encourage ongoing{" "}
                    <br />
                    cooperation among team members
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div
            className="card mx-auto mb-3 shadow-lg"
            data-aos="fade-up"
            style={{ width: "50vw", borderRadius: "30px", marginTop: "50px" }}
          >
            <div className="row g-0">
              <div className="col">
                <div className="card-body text-center">
                  <i
                    className="fa-solid fa-laptop fa-2xl"
                    style={{ color: "#B197FC", marginTop: "30px" }}
                  ></i>
                  <h4
                    className="card-title text-center"
                    style={{ marginTop: "30px" }}
                  >
                    Equipment and Tools
                  </h4>
                  <p
                    className="card-text text-center"
                    style={{ color: "black" }}
                  >
                    {/* We offer our team members the chance to expand their knowledge
                  and skills by learning from their colleagues.  */}
                    MacBooks, iPhones, supporting accessories and more tech{" "}
                    <br /> are provided to our employees to maximize their
                    workflow.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div
            className="card mx-auto mb-3 shadow-lg"
            data-aos="fade-up"
            style={{ width: "50vw", borderRadius: "30px", marginTop: "50px" }}
          >
            <div className="row g-0">
              <div className="col">
                <div className="card-body text-center">
                  <i
                    className="fa-solid fa-hourglass-start fa-2xl"
                    style={{ color: "#B197FC", marginTop: "30px" }}
                  ></i>
                  <h4
                    className="card-title text-center"
                    style={{ marginTop: "30px" }}
                  >
                    Flexible working hours
                  </h4>
                  <p
                    className="card-text text-center"
                    style={{ color: "black" }}
                  >
                    {/* We offer our team members the chance to expand their knowledge
                  and skills by learning from their colleagues.  */}
                    We don't encourage our team to exceed their capacity, and we{" "}
                    <br />
                    respect their choice to take time off when needed.
                    Individuals
                    <br />
                    are free to work in their preferred manner without any undue
                    pressure.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{
            marginTop: "10px",
            height: "80vh",
            backgroundImage: "url('/assets/Meeting.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        ></div>
      </div>
    </>
  );
};

export default Team;
