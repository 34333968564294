import React from "react";

const Process = () => {
  return (
    <>
      <div
        className="container d-flex align-items-center position-relative h-100 "
        style={{ marginTop: "65px" }}
      ></div>

      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS26.jpg"
                  />
                </div>
                <></>
                <div className="col-md-6 mt-5">
                  <p
                    className="mb-3"
                    style={{
                      color: "#02a9eb",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    STRATEGY & IDEATION
                  </p>
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Collaborating closely with your stakeholders, we cultivate
                    clarity and alignment. Through the implementation of
                    efficient methodologies, we guarantee that your entire team
                    remains synchronized with each other and attuned to your
                    customer's needs.
                  </p>

                  <ul
                    style={{
                      fontSize: "20px",
                      columns: "2",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <li>Service design</li>
                    <li>Customer journey mapping</li>
                    <li>Opportunity mapping</li>
                    <li>Competitive analysis</li>
                    <li>Market analysis</li>
                    <li>Stakeholder alignment</li>
                    <li>Audience segmentation</li>
                    <li>Customer personas</li>
                    <li>Market analysis</li>
                    <li>Stakeholder workshops</li>
                    <li>Audience segmentation</li>
                    <li>Customer personas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS27.jpg"
                  />
                </div>
                <></>
                <div className="col-md-6 mt-5">
                  <p
                    className="mb-3"
                    style={{
                      color: "#02a9eb",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    USER RESEARCH
                  </p>
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    We leverage lean experiments and psychology-infused
                    methodologies to swiftly unveil your customers' true
                    desires: their pain points, purchasing behaviors, pivotal
                    moments, and perceptions of your brand.
                  </p>
                  <ul
                    style={{
                      fontSize: "20px",
                      columns: "2",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <li>Customer interviews</li>
                    <li>Biometric response</li>
                    <li>Eye-tracking</li>
                    <li>Focus groups</li>
                    <li>Diary studies</li>
                    <li>Prototyping</li>
                    <li>Usability testing</li>
                    <li>Contextual inquiries</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>

      <div className="row g-0">
        <div className="col-md-18" data-aos="fade-up">
          <div className="text-center">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    className="mt-5 img-fluid"
                    style={{ borderRadius: "10px", height: "350px" }}
                    src="/assets/SS28.jpg"
                  />
                </div>

                <div className="col-md-6 mt-5" style={{}}>
                  <p
                    className="mb-3"
                    style={{
                      color: "#02a9eb",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    HUMAN-CENTRIC DESIGN
                  </p>
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Through an iterative approach, we aid in prioritizing
                    features to expedite market entry. Our seasoned team stands
                    ready to design, develop, deploy, and maintain mobile apps,
                    web apps, custom software, and internal portals.
                  </p>
                  <ul
                    style={{
                      textAlign: "justify",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <li>Wireframing</li>
                    <li>Prototyping</li>
                    <li>Usability testing</li>
                    <li>Product roadmap</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default Process;
