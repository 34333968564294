import React from "react";

const Buttongroup = ({ buttons, isSelected, setIsSelected }) => {
  return (
    <div>
      {buttons.map((text, index) => (
        <button 
          key={index} 
          style={{
            margin: "5px",
            color: isSelected === index ? "white": "#168FF7",
            backgroundColor: isSelected === index ? "#168FF7" : "",
            border: isSelected === index ? "none" : "1px solid #ccc",
            padding: isSelected === index ? "10px 20px" : "5px 15px",
            borderRadius: isSelected === index ? "5px" : "5px",
          }} 
          className={isSelected === index ? "selected-button" : "btn btn-light"} 
          onClick={() => setIsSelected(index)}
        >
          {text}
        </button>
      ))}
    </div>
  );
};

export default Buttongroup;
