import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import Process from "./Process";
const UserExperience = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            marginTop: "55px",
            height: "100vh",
            backgroundImage: "url('/assets/SS29.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            position: "relative", // Make the parent container relative
          }}
        >
          <div className="container d-flex align-items-center position-relative h-50">
            <div className="row w-100">
              <div className="col-md-6 align-items-center">
                <div
                  className="animate__animated animate__bounce animate__repeat-2"
                  style={{ color: "white" }}
                >
                  <h1
                    className=""
                    style={{
                      color: "white",
                      marginTop: "40%",
                    }}
                  >
                    User Experience
                  </h1>
                  <br />
                </div>
                <p
                  className="animate__animated animate__fadeInLeft"
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  Achieving a captivating and seamless user experience is the
                  most reliable route to business success.
                </p>
              </div>
            </div>
          </div>

          <Link
            activeclassName="active"
            to="user"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="down-button"
            style={{
              position: "absolute",
              bottom: "70px",
              left: "50%",
              transform: "translateX(-50%)", // Center the button horizontally
              cursor: "pointer",
            }}
          >
            <div>
              <i
                className="fas fa-angle-down animate__animated animate__bounce animate__repeat-3"
                style={{
                  fontSize: "30px",
                  textDecoration: "none",
                  color: "white",
                }}
              ></i>
            </div>
          </Link>
        </div>
        <div
          className="text-center"
          style={{
            marginTop: "8%",
          }}
        >
          <div id="user" className="container text-center">
            <div className="row align-items-center">
              <div
                className="col-md-5"
                style={{
                  textAlign: "start",
                  color: "black",
                }}
              >
                <h2 style={{ color: "#02a9eb" }}>
                  We'll develop your prototype within weeks, not months.
                </h2>
              </div>
              <div
                className="col-md-7"
                style={{
                  textAlign: "justify",
                  fontSize: "17px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                <p>
                  Employing a blend of low-tech methods such as whiteboarding,
                  wireframing, and journey mapping alongside high-tech
                  techniques like eye-tracking and biometric response
                  measurement, we uncover profound insights about your
                  customers.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid items-center justify-content-center"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundColor: "#F7FFED",
          }}
        >
          <div className="container text-center">
            <div className="row align-items-center mb-5 mt-5 p-5">
              <div className="col-md-6 mb-5">
                <h3 style={{ textAlign: "start",color: "grey" }}>User research</h3>
                <p style={{ textAlign: "start" }}>
                  Through the utilization of lean experiments, design thinking,
                  and research methodologies grounded in psychology and
                  neuroscience, we swiftly and efficiently identify your target
                  audience while unearthing crucial insights about your
                  customers.
                </p>
              </div>
              <div className="col-md-6 mb-5">
                <h3 style={{ textAlign: "start",color: "grey" }}>Unified experiences</h3>
                <p style={{ textAlign: "start" }}>
                  Employing an iterative approach and our established design
                  sprint process, we construct prototypes for real customer
                  testing. This strategy, characterized by low risk and high
                  value, furnishes your team with the necessary validation
                  before committing to significant investments.
                </p>
              </div>
              <div className="col-md-6">
                <h3 style={{ textAlign: "start",color: "grey" }}>Prototype</h3>
                <p style={{ textAlign: "start" }}>
                  Using lean experiments, design thinking, and research methods
                  rooted in psychology and neuroscience, we identify your target
                  audience and uncover critical insights about your customers
                  quickly and efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Process/>
      </div>
    </>
  );
};

export default UserExperience;
