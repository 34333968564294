import React from "react";

const Stragey = () => {
  return (
    <>
      <div
        className="container-fluid items-center justify-content-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          backgroundColor: "#F7FFED",
          marginTop: "8%",
        }}
      >
        <div className="row g-0">
          <div className="col-md-18" data-aos="fade-up">
            <div className="text-center" style={{}}>
              <img
                className="mt-3 mb-3 mr-5 img-fluid"
                style={{ borderRadius: "10px", height: "80%", width: "80%" }}
                src="/assets/SS25.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stragey;
