import React from "react";

const IOSApp = () => {
  return (
    <>
      <div
        className="card p-3"
        style={{
          marginTop: "30px",
          marginLeft: "60px",
          borderRadius: "20px",
          backgroundColor: "#168FF7",
          color: "white",
          textAlign: "left", // Align content to the left
        }}
      >
        <div className="card-body" style={{ paddingLeft: "20px" }}>
          {" "}
          {/* Add padding-left for better alignment */}
          <img
            src="/assets/Vector-1.svg"
            className="mb-5"
            style={{
              height: "80px",
            }}
          />
          <h5 className="card-title mb-3">IOS App Development</h5>
          <p className="card-text">
            Elevate your brand presence on iOS devices with our top-notch iOS
            app development services. Our seasoned developers harness the power
            of Swift and other cutting-edge technologies to deliver
            high-performance iOS applications tailored to your business
            objectives. Whether you're targeting iPhone, iPad, or Apple Watch
            users, we ensure a flawless user experience and adherence to Apple's
            stringent guidelines. Expand your reach on the iOS platform and
            captivate users with our expertly crafted iOS app development
            solutions
          </p>
        </div>
      </div>
    </>
  );
};

export default IOSApp;
